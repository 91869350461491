//
// map.js
// Theme module
//

const maps = document.querySelectorAll('[data-map]');
const accessToken = 'pk.eyJ1IjoibWFyY21laW5lcnMiLCJhIjoiY2xob21hYzU1MWk0ZzNlb2R3cm9wYWc3ZSJ9.2JXaUcMeAgozYmkhxMzt6A';

maps.forEach((map) => {
  const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

  const defaultOptions = {
    container: map,
    style: 'mapbox://styles/mapbox/light-v9',
    scrollZoom: false,
    interactive: false,
  };

  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  // Get access token
  mapboxgl.accessToken = accessToken;

  // Init map
  const mapInstance = new mapboxgl.Map(options);

  // Add marker
  const marker = new mapboxgl.Marker({color: 'black'})
      .setLngLat(options.center)
      .addTo(mapInstance);
});
